import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // PropTypesをインポート
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import '../App.css';

const NotificationAwaitingPreview = ({ notificationId }) => {
  const [titleJa, setTitleJa] = useState('');
  const [contentJa, setContentJa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [contentEn, setContentEn] = useState('');

  useEffect(() => {
    const fetchNotification = async () => {
      if (notificationId) {
        try {
          const docRef = doc(db, 'notifications', notificationId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setTitleJa(data.title_ja);
            setContentJa(data.content_ja);
            setTitleEn(data.title_en);
            setContentEn(data.content_en);
          }
        } catch (error) {
          console.error('お知らせの取得中にエラーが発生しました:', error);
        }
      }
    };

    fetchNotification();
  }, [notificationId]);

  const previewStyle = {
    width: '375px', 
    height: '667px', 
    border: '1px solid #ccc', 
    overflow: 'auto', 
    padding: '10px',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* 日本語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleJa}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentJa }} />
        </div>
      </div>
      {/* 英語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleEn}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentEn }} />
        </div>
      </div>
      {/* 右エリア（空） */}
      <div style={{ width: '33%', padding: '20px' }}>
        {/* ここは空のままにしておきます */}
      </div>
    </div>
  );
};

// PropTypesを追加してプロパティの型を明示
NotificationAwaitingPreview.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default NotificationAwaitingPreview;
