import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import '../App.css';

const ManualAwaitingPreview = ({ manualId }) => {
  const [titleJa, setTitleJa] = useState('');
  const [contentJa, setContentJa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    const fetchManual = async () => {
      if (manualId) {
        try {
          const docRef = doc(db, 'manuals', manualId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setTitleJa(data.title_ja);
            setContentJa(data.content_ja);
            setTitleEn(data.title_en);
            setContentEn(data.content_en);
            setSelectedCategories(data.categories || []);
          }
        } catch (error) {
          console.error('マニュアルの取得中にエラーが発生しました:', error);
        }
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, 'category'));
        const categoriesData = {};
        categoriesSnapshot.forEach((doc) => {
          const data = doc.data();
          categoriesData[data.category_id] = {
            title_ja: data.title_ja,
            title_en: data.title_en
          };
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('カテゴリーの取得中にエラーが発生しました:', error);
      }
    };

    fetchManual();
    fetchCategories();
  }, [manualId]);

  const previewStyle = {
    width: '375px', 
    height: '667px', 
    border: '1px solid #ccc', 
    overflow: 'auto', 
    padding: '10px',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* 日本語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleJa}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentJa }} />
        </div>
      </div>
      {/* 英語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleEn}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentEn }} />
        </div>
      </div>
      {/* 右エリア（カテゴリー表示） */}
      <div style={{ width: '33%', padding: '20px' }}>
        <h3>選択されたカテゴリー</h3>
        {selectedCategories.length > 0 ? (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {selectedCategories.map((categoryId, index) => (
              <li key={index} style={{ 
                backgroundColor: '#f0f0f0', 
                padding: '10px', 
                margin: '5px 0', 
                borderRadius: '5px'
              }}>
                {categoryId} ({categories[categoryId] ? `${categories[categoryId].title_ja}, ${categories[categoryId].title_en}` : '不明なカテゴリー'})
              </li>
            ))}
          </ul>
        ) : (
          <p>選択されたカテゴリーはありません。</p>
        )}
      </div>
    </div>
  );
};

ManualAwaitingPreview.propTypes = {
  manualId: PropTypes.string.isRequired,
};

export default ManualAwaitingPreview;