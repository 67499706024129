import React, { useState } from 'react';
import CategoryCreateAndList from './ManualCategoryCreateAndList'; // 先ほどの画面をインポート

const styles = {
  adminPage: {
    padding: '40px 240px',
  },
  heading: {
    marginBottom: '20px',
  },
  createButton: {
    marginBottom: '20px',
    padding: '10px 20px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContent: {
    width: '95%',
    maxWidth: '95vw',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    overflowY: 'auto',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

const ManualCategoryManagement = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // ポップアップ表示状態の管理

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen); // ポップアップの表示/非表示を切り替え
  };

  return (
    <div style={styles.adminPage}>
      <h1 style={styles.heading}>カテゴリー管理</h1>
      <button onClick={togglePopup} style={styles.createButton}>
        カテゴリーを編集
      </button>

      {/* ポップアップの表示 */}
      {isPopupOpen && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <CategoryCreateAndList /> {/* カテゴリー作成画面 */}
            <button onClick={togglePopup} style={styles.closeButton}>
              閉じる
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManualCategoryManagement;
