import React from 'react';
import ManualManagement from '../components/ManualManagement';
import ManualCategoryManagement from '../components/ManualCategoryManagement';

const ManualManagementPage = () => {
  return (
    <div>
      <ManualCategoryManagement />
      <ManualManagement />
    </div>
  );
};

export default ManualManagementPage;
