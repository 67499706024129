import React, { useEffect, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Manual from './pages/ManualNew';
import FeedbackList from './pages/Feedback_list';
import Login from './pages/Login';
import Notification from './pages/Notification';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { auth, fetchUserData } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import useStore from './store';

const AUTO_LOGOUT_TIME = 2 * 60 * 60 * 1000;
const WARNING_TIME = AUTO_LOGOUT_TIME - 10 * 60 * 1000;

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const setUid = useStore((state) => state.setUid);
  const setUser = useStore((state) => state.setUser);
  const navigate = useNavigate();
  const logoutTimerRef = useRef(null);
  const warningTimerRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const uid = user.uid;
          const userData = await fetchUserData(uid);

          if (userData.account_status !== 0) {
            await auth.signOut();
            setUid(null);
            setUser(null);
            navigate('/login');
            return;
          }

          setUid(uid);
          setUser(userData);

          if (isLoginPage) {
            navigate('/home');
          }

          if (logoutTimerRef.current) {
            clearTimeout(logoutTimerRef.current);
          }
          if (warningTimerRef.current) {
            clearTimeout(warningTimerRef.current);
          }

          warningTimerRef.current = setTimeout(() => {
            alert('あと10分でログインして2時間経過するため強制的にログアウトされます。必要なデータは保存することを忘れないようにしてください。');
          }, WARNING_TIME);

          logoutTimerRef.current = setTimeout(async () => {
            window.alert('ログインから2時間経過したため強制的にログアウトされました。OKを押すとログイン画面に戻ります。');
            await auth.signOut();
            setUid(null);
            setUser(null);
            navigate('/login');
          }, AUTO_LOGOUT_TIME);

        } catch (error) {
          console.error('ユーザー情報の取得に失敗しました:', error);
          await auth.signOut();
          setUid(null);
          setUser(null);
          navigate('/login');
        }
      } else {
        setUid(null);
        setUser(null);
        if (!isLoginPage) {
          navigate('/login');
        }
      }
    });

    return () => {
      unsubscribe();
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }
    };
  }, [setUid, setUser, navigate, isLoginPage]);

  return (
    <div className="App">
      {!isLoginPage && <Header />}
      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/manual" element={<Manual />} />
            <Route path="/feedback" element={<FeedbackList />} />
            <Route path="/notification" element={<Notification />} />
          </Route>
        </Routes>
      </main>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
