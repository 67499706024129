import { create } from 'zustand';

// 初期のユーザー状態
const initialUserState = {
  account_status: null,
  avatar: '',
  email: '',
  name: ''
};

const useStore = create((set) => ({
  uid: null,
  user: initialUserState,
  setUid: (uid) => set({ uid }),
  setUser: (user) => set((state) => ({ user: { ...state.user, ...user } })),
  clearUid: () => set({ uid: null }),
  clearUser: () => set({ user: initialUserState })
}));

export default useStore;