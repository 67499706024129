import React, { useEffect } from 'react';
import useStore from '../store';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { uid, user } = useStore((state) => ({
    uid: state.uid,
    user: state.user,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid || !user) {
      navigate('/login');
    }
  }, [uid, user, navigate]);

  if (!uid || !user) {
    return null; // またはローディングスピナーを表示
  }

  return (
    <div style={styles.home}>
      <h1 style={styles.heading}>Home</h1>
      <p style={styles.text}>ユーザーID: {uid}</p>
      <p style={styles.text}>名前: {user.name}</p>
      <p style={styles.text}>メール: {user.email}</p>
      <p style={styles.text}>アカウントステータス: {user.account_status}</p>
      <img src={user.avatar} alt="Avatar" style={styles.avatar} />
    </div>
  );
};

// インラインスタイルを定義
const styles = {
  home: {
    backgroundColor: '#D6BDA5', // 背景色を指定
    padding: '40px',
    width: '80%',
    maxWidth: '800px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px auto',
    borderRadius: '40px',
  },
  heading: {
    fontSize: '36px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '18px',
    margin: '10px 0',
  },
  avatar: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginTop: '20px',
  },
};

export default Home;
