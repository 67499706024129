import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import useStore from '../store';

const FeedbackList = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [statusFilter, setStatusFilter] = useState('すべて');
  const [categoryFilter, setCategoryFilter] = useState('すべて');
  const { uid, user } = useStore();
  const [selectedFeedbackHistory, setSelectedFeedbackHistory] = useState(null);
  const [comments, setComments] = useState({});
  const [ainnaComments, setAinnaComments] = useState({});

  const statusMap = {
    0: '新規',
    1: '受付済',
    2: '対応中',
    3: '完了',
    5: '保留',
    9: '見送'
  };

  const categoryMap = {
    1: '機能リクエスト',
    2: 'バグ報告',
    99: 'その他'
  };

  useEffect(() => {
    const fetchFeedbacks = async () => {
      const q = query(collection(db, 'feedback'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const feedbackList = await Promise.all(querySnapshot.docs.map(async doc => {
        const feedbackData = doc.data();
        const statusSubcollection = collection(doc.ref, 'status');
        const statusQuery = query(statusSubcollection, orderBy('status_change_datetime', 'desc'));
        const statusSnapshot = await getDocs(statusQuery);
        
        const statusHistory = statusSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));

        const latestStatus = statusHistory[0] || { statusValue: 0, status_change_datetime: null, status_chenge_member: null, status_change_member_id: null };

        return {
          id: doc.id,
          ...feedbackData,
          status: statusMap[latestStatus.statusValue] || '新規',
          statusUpdateMember: latestStatus.status_chenge_member,
          statusUpdateDateTime: latestStatus.status_change_datetime,
          statusUpdateMemberId: latestStatus.status_change_member_id,
          statusHistory: statusHistory,
          categoryValue: feedbackData.categoryValue
        };
      }));
      setFeedbacks(feedbackList);
      setFilteredFeedbacks(feedbackList);

      // フィードバックを取得した後、コメントも取得します
      await fetchComments(feedbackList);
    };

    fetchFeedbacks();
  }, []);

  useEffect(() => {
    let filtered = feedbacks;
    
    if (statusFilter !== 'すべて') {
      filtered = filtered.filter(feedback => feedback.status === statusFilter);
    }
    
    if (categoryFilter !== 'すべて') {
      filtered = filtered.filter(feedback => categoryMap[feedback.categoryValue] === categoryFilter);
    }

    setFilteredFeedbacks(filtered);
  }, [statusFilter, categoryFilter, feedbacks]);

  const fetchComments = async (feedbackList) => {
    const commentsPromises = feedbackList.map(async (feedback) => {
      const commentsCollectionRef = collection(doc(db, 'feedback', feedback.id), 'ainna_comment');
      const commentsQuery = query(commentsCollectionRef, orderBy('createdAt', 'desc'));
      const commentsSnapshot = await getDocs(commentsQuery);
      
      return [feedback.id, commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))];
    });

    const commentsResults = await Promise.all(commentsPromises);
    const newAinnaComments = Object.fromEntries(commentsResults);
    setAinnaComments(newAinnaComments);
  };

  const handleStatusChange = async (id, newStatus) => {
    const statusValue = Number(Object.keys(statusMap).find(key => statusMap[key] === newStatus));
    const updatedFeedbacks = feedbacks.map(feedback => {
      if (feedback.id === id) {
        const newStatusHistory = [
          {
            statusValue: statusValue,
            status_change_datetime: Timestamp.now(),
            status_chenge_member: user.name,
            status_change_member_id: uid
          },
          ...feedback.statusHistory
        ];
        return { 
          ...feedback, 
          status: newStatus,
          statusUpdateMember: user.name,
          statusUpdateDateTime: Timestamp.now(),
          statusUpdateMemberId: uid,
          statusHistory: newStatusHistory
        };
      }
      return feedback;
    });
    setFeedbacks(updatedFeedbacks);

    const feedbackRef = doc(db, 'feedback', id);
    const statusCollectionRef = collection(feedbackRef, 'status');
    await addDoc(statusCollectionRef, {
      statusValue: statusValue,
      status_change_datetime: Timestamp.now(),
      status_chenge_member: user.name,
      status_change_member_id: uid
    });
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleShowHistory = (feedbackId) => {
    const feedback = feedbacks.find(f => f.id === feedbackId);
    setSelectedFeedbackHistory(feedback.statusHistory);
  };

  const handleCloseHistory = () => {
    setSelectedFeedbackHistory(null);
  };

  const renderContent = (content) => {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handleCommentChange = (feedbackId, value) => {
    setComments(prevComments => ({
      ...prevComments,
      [feedbackId]: value
    }));
  };

  const handleCommentSubmit = async (feedbackId) => {
    const comment = comments[feedbackId];
    if (!comment) return;

    try {
      const feedbackRef = doc(db, 'feedback', feedbackId);
      const ainnaCommentCollectionRef = collection(feedbackRef, 'ainna_comment');
      
      await addDoc(ainnaCommentCollectionRef, {
        content: comment,
        createdAt: Timestamp.now(),
        userId: uid,
        userName: user.name
      });

      console.log(`フィードバック ${feedbackId} へのコメントを保存しました`);

      // コメントを保存した後、最新のコメントを再取得します
      const updatedCommentsQuery = query(ainnaCommentCollectionRef, orderBy('createdAt', 'desc'));
      const updatedCommentsSnapshot = await getDocs(updatedCommentsQuery);
      
      const updatedComments = updatedCommentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setAinnaComments(prevComments => ({
        ...prevComments,
        [feedbackId]: updatedComments
      }));

      // 入力フィールドをクリアします
      setComments(prevComments => ({
        ...prevComments,
        [feedbackId]: ''
      }));
    } catch (error) {
      console.error("コメントの保存中にエラーが発生しました:", error);
    }
  };

  const renderAinnaComments = (feedbackId) => {
    const feedbackComments = ainnaComments[feedbackId] || [];
    return (
      <div className="ainna-comments">
        {feedbackComments.map((comment) => (
          <div key={comment.id} className="ainna-comment">
            <p><strong>{comment.userName}</strong> - {comment.createdAt.toDate().toLocaleString()}</p>
            <p>{comment.content}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="feedback-list" style={{ padding: '40px 240px' }} >
      <h1>フィードバック一覧</h1>
      <div className="filter-container">
        <div className="filter-item">
          <label htmlFor="category-filter">カテゴリー：</label>
          <select
            id="category-filter"
            value={categoryFilter}
            onChange={handleCategoryFilterChange}
            className="filter-dropdown"
          >
            <option value="すべて">すべて</option>
            {Object.values(categoryMap).map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="status-filter">ステータス：</label>
          <select
            id="status-filter"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className="filter-dropdown"
          >
            <option value="すべて">すべて</option>
            {Object.values(statusMap).map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
      </div>
      {filteredFeedbacks.map(feedback => (
        <div key={feedback.id} className="feedback-item">
          <div className="feedback-title">
            <h2>{categoryMap[feedback.categoryValue] || 'その他'}</h2>
            <div className="status-container">
              <select 
                value={feedback.status} 
                onChange={(e) => handleStatusChange(feedback.id, e.target.value)}
                className="status-dropdown"
              >
                {Object.values(statusMap).map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <div className="status-info">
                <p>
                  更新者: {feedback.statusUpdateMember || '（新規）'}
                  {feedback.statusUpdateMemberId && (
                    <span className="status-update-member-id">
                      <br />ID: {feedback.statusUpdateMemberId}
                    </span>
                  )}
                </p>
                <p>更新日時: {feedback.statusUpdateDateTime ? feedback.statusUpdateDateTime.toDate().toLocaleString() : '（新規）'}</p>
                <button onClick={() => handleShowHistory(feedback.id)}>履歴を表示</button>
              </div>
            </div>
          </div>
          <div className="feedback-summary">
            <p><strong>作成日時:</strong> {new Date(feedback.createdAt.seconds * 1000).toLocaleString()}</p>
            <p><strong>ユーザーID:</strong> {feedback.userId}</p>
            <p><strong>名前:</strong> {feedback.name}</p>
            <p><strong>アカウント状態:</strong> {feedback.accountStatus}</p>
            <p><strong>メールアドレス:</strong> {feedback.email}</p>
            <p><strong>カテゴリー値:</strong> {feedback.categoryValue}</p>
          </div>
          <hr className="feedback-divider" />
          <div className="feedback-content">
            <p><strong>内容:</strong></p>
            <p>{renderContent(feedback.content)}</p>
            {feedback.imageUrls && feedback.imageUrls.length > 0 && (
              <div className="images">
                {feedback.imageUrls.map((url, index) => (
                  <img key={index} src={url} alt={`フィードバック画像 ${index}`} width="100" />
                ))}
              </div>
            )}
          <hr className="feedback-divider" />
            <div className="comment-section">
              <p><strong>AINNAメンバーコメント:</strong></p>
              {renderAinnaComments(feedback.id)}
              <textarea
                value={comments[feedback.id] || ''}
                onChange={(e) => handleCommentChange(feedback.id, e.target.value)}
                placeholder="コメントを入力してください"
                rows="3"
              />
              <button onClick={() => handleCommentSubmit(feedback.id)}>コメントを送信</button>
            </div>
          </div>
        </div>
      ))}
      {selectedFeedbackHistory && (
        <div className="history-popup">
          <div className="history-content">
            <h3>ステータス更新履歴</h3>
            <ul>
              {selectedFeedbackHistory.map((history, index) => (
                <li key={index}>
                  <p>ステータス: {statusMap[history.statusValue]}</p>
                  <p>更新日時: {history.status_change_datetime.toDate().toLocaleString()}</p>
                  <p>更新者: {history.status_chenge_member}</p>
                  <p>更新者ID: {history.status_change_member_id}</p>
                </li>
              ))}
            </ul>
            <button onClick={handleCloseHistory}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackList;