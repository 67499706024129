import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // dbを正しくインポート
import { collection, getDocs, orderBy, query, addDoc, doc, deleteDoc } from 'firebase/firestore'; // Firestoreの関数をインポート

const styles = {
  container: {
    padding: '40px 240px',
  },
  heading: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '14px',
  },
  createButtonDisabled: {
    padding: '10px 20px',
    backgroundColor: '#cccccc',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    width: '40%',
    minWidth: '160px',
    cursor: 'not-allowed',
  },
  createButtonEnabled: {
    padding: '10px 20px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    width: '40%',
    minWidth: '160px',
    cursor: 'pointer',
  },
  translateButton: {
    padding: '10px 20px',
    backgroundColor: '#ADD8E6',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '40%',
    minWidth: '160px',
    marginBottom: '8px',
    marginRight: '8px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  noCategoryText: {
    fontSize: '14px',
    color: '#888',
  },
  listContainer: {
    marginTop: '20px',
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: '1px solid #ccc',
  },
  itemContent: {
    flex: 1,
    fontSize: '14px',
  },
  deleteButton: {
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#D58A6A',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
  },
  list: {
    listStyleType: 'none', // リストマーカーを削除
    padding: 0, // パディングをリセット
  },
  listItem: {
    display: 'grid', // グリッドレイアウトを使用
    gridTemplateColumns: '150px auto', // 1列目は固定幅、2列目は自動幅
    marginBottom: '0px', // リスト項目の間隔を調整
  },
  label: {
    fontWeight: 'bold', // ラベルのスタイルを太字に
  },
  content: {
    textAlign: 'left', // コンテンツの左揃え
  }
};

const CategoryCreateAndList = () => {
  const [categoryId, setCategoryId] = useState('');
  const [titleJa, setTitleJa] = useState('');
  const [messageJa, setMessageJa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [messageEn, setMessageEn] = useState('');
  const [order, setOrder] = useState(''); // orderフィールドを追加
  const [categories, setCategories] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false); 
  
  // フォームが全て入力されているかどうかを確認する
  useEffect(() => {
    if (
      categoryId.trim() !== '' &&
      titleJa.trim() !== '' && 
      messageJa.trim() !== '' && 
      titleEn.trim() !== '' && 
      messageEn.trim() !== '' &&
      order.trim() !== '' // orderフィールドをチェック
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [categoryId, titleJa, messageJa, titleEn, messageEn, order]);

  // Firestoreからカテゴリーを取得する関数
  const fetchCategories = async () => {
    try {
      const q = query(collection(db, 'category'), orderBy('order', 'asc')); // orderフィールドで並び替え
      const querySnapshot = await getDocs(q);
      const categoryList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoryList);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCreateCategory = async () => {
    if (!isFormComplete) {
      alert('全ての項目を入力してください。');
      return;
    }

    const categoryIdPattern = /^[a-zA-Z]+$/;
    if (!categoryIdPattern.test(categoryId)) {
      alert('カテゴリーIDは半角アルファベット（大文字小文字）のみで入力してください。');
      return;
    }

    // orderが数値であることを確認
    const orderNumber = parseInt(order, 10);
    if (isNaN(orderNumber)) {
      alert('順序は数値で入力してください。');
      return;
    }

    const newCategory = {
      category_id: categoryId,
      title_ja: titleJa,
      message_ja: messageJa,
      title_en: titleEn,
      message_en: messageEn,
      order: orderNumber, // orderを追加
      createdAt: new Date(),
    };
    try {
      await addDoc(collection(db, 'category'), newCategory);
      setCategoryId('');
      setTitleJa('');
      setMessageJa('');
      setTitleEn('');
      setMessageEn('');
      setOrder(''); // orderをリセット
      fetchCategories();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    const confirmDelete = window.confirm('このカテゴリーを削除してよろしいですか？');
    if (confirmDelete) {
      try {
        const categoryRef = doc(db, 'category', id);
        await deleteDoc(categoryRef);
        setCategories(categories.filter(category => category.id !== id)); // 削除後にリストを更新
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  // テキスト翻訳関数
  const translateText = async (text, sourceLang, targetLang) => {
    const functionUrl = 'https://us-central1-conoty-a33b9.cloudfunctions.net/openai_request';
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        prompt: `Translate the following ${sourceLang} text to ${targetLang}: "${text}"`
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`翻訳リクエストが失敗しました: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    return data.response.trim();
  };

  const handleTranslateJaToEn = async () => {
    try {
      const translatedTitle = await translateText(titleJa, 'Japanese', 'English');
      const translatedMessage = await translateText(messageJa, 'Japanese', 'English');
      setTitleEn(translatedTitle);
      setMessageEn(translatedMessage);
      alert('日本語から英語への翻訳が完了しました');
    } catch (error) {
      console.error('翻訳中にエラーが発生しました:', error);
      alert(`翻訳中にエラーが発生しました: ${error.message}`);
    }
  };

  const handleTranslateEnToJa = async () => {
    try {
      const translatedTitle = await translateText(titleEn, 'English', 'Japanese');
      const translatedMessage = await translateText(messageEn, 'English', 'Japanese');
      setTitleJa(translatedTitle);
      setMessageJa(translatedMessage);
      alert('英語から日本語への翻訳が完了しました');
    } catch (error) {
      console.error('翻訳中にエラーが発生しました:', error);
      alert(`翻訳中にエラーが発生しました: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>カテゴリー作成</h2>

      <div style={styles.inputGroup}>
        <input
          type="text"
          placeholder="カテゴリーID (半角アルファベット)"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="カテゴリータイトル（日本語）"
          value={titleJa}
          onChange={(e) => setTitleJa(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="表示メッセージ（日本語）"
          value={messageJa}
          onChange={(e) => setMessageJa(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="Category Title（English）"
          value={titleEn}
          onChange={(e) => setTitleEn(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="Show message（English）"
          value={messageEn}
          onChange={(e) => setMessageEn(e.target.value)}
          style={styles.input}
        />
        <input
          type="number"
          placeholder="順序（数値）"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          style={styles.input}
        />
        <div style={styles.buttonGroup}>
          <button onClick={handleTranslateJaToEn} style={styles.translateButton}>
            日本語から英語に翻訳
          </button>
          <button onClick={handleTranslateEnToJa} style={styles.translateButton}>
            英語から日本語に翻訳
          </button>
        </div>
        <button
          onClick={handleCreateCategory}
          style={isFormComplete ? styles.createButtonEnabled : styles.createButtonDisabled}
          disabled={!isFormComplete}
        >
          カテゴリー作成
        </button>
      </div>

      <div style={styles.listContainer}>
        {categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.id} style={styles.item}>
              <div style={styles.itemContent}>
                <ul style={styles.list}>
                  <li style={styles.listItem}>
                    <span style={styles.label}>順序 :</span>
                    <span style={styles.content}>{category.order}</span>
                  </li>
                  <li style={styles.listItem}>
                    <span style={styles.label}>カテゴリーID :</span>
                    <span style={styles.content}>{category.category_id}</span>
                  </li>
                  <li style={styles.listItem}>
                    <span style={styles.label}>タイトル :</span>
                    <span style={styles.content}>{category.title_ja}</span>
                  </li>
                  <li style={styles.listItem}>
                    <span style={styles.label}>表示メッセージ :</span>
                    <span style={styles.content}>{category.message_ja}</span>
                  </li>
                  <li style={styles.listItem}>
                    <span style={styles.label}>Title :</span>
                    <span style={styles.content}>{category.title_en}</span>
                  </li>
                  <li style={styles.listItem}>
                    <span style={styles.label}>Show Message :</span>
                    <span style={styles.content}>{category.message_en}</span>
                  </li>
                </ul>
              </div>
              <button
                style={styles.deleteButton}
                onClick={() => handleDeleteCategory(category.id)}
              >
                削除
              </button>
            </div>
          ))
        ) : (
          <p style={styles.noCategoryText}>作成されたカテゴリーはありません</p>
        )}
      </div>
    </div>
  );
};

export default CategoryCreateAndList;
