import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import { doc, getDoc, collection, addDoc, query, getDocs, setDoc, deleteDoc } from "firebase/firestore";
import useStore from '../store';
import '../App.css';

const NotificationApprovalPage = ({ notificationId }) => {
  const [title_ja, setTitleJa] = useState('');
  const [content_ja, setContentJa] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [content_en, setContentEn] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [isUrgent, setIsUrgent] = useState(false);
  const [checks, setChecks] = useState({
    confidentialInfo: false,
    branding: false,
    imageUsage: false,
    linkCorrect: false,
    timingAppropriate: false,
  });
  const [selectedLanguage, setSelectedLanguage] = useState('ja');

  const { uid, user } = useStore();

  const isFormValid = Object.values(checks).every(Boolean) && selectedDate;

  const fetchComments = async () => {
    if (notificationId) {
      try {
        const commentsCollectionRef = collection(db, 'notifications', notificationId, 'comments');
        const commentsQuery = query(commentsCollectionRef);
        const querySnapshot = await getDocs(commentsQuery);
        const fetchedComments = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(fetchedComments);
      } catch (error) {
        console.error('コメントの取得中にエラーが発生しました:', error);
      }
    }
  };

  useEffect(() => {
    const fetchNotification = async () => {
      if (notificationId) {
        try {
          const docRef = doc(db, 'notifications', notificationId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setTitleJa(data.title_ja || '');
            setContentJa(data.content_ja || '');
            setTitleEn(data.title_en || '');
            setContentEn(data.content_en || '');
          }
        } catch (error) {
          console.error('お知らせの取得中にエラーが発生しました:', error);
        }
      }
    };

    fetchNotification();
    fetchComments();
  }, [notificationId]);

  const handleAddComment = async () => {
    if (newComment.trim() === '') return;

    try {
      const commentsCollectionRef = collection(db, 'notifications', notificationId, 'comments');
      await addDoc(commentsCollectionRef, {
        text: newComment,
        createdAt: new Date(),
      });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('コメントの追加中にエラーが発生しました:', error);
    }
  };

  const handleRevertNotification = async () => {
    if (!notificationId) return;

    try {
      const docRef = doc(db, 'notifications', notificationId);
      await setDoc(docRef, { status: 'draft' }, { merge: true });
      alert('お知らせのステータスが「差し戻し」に更新されました。');
    } catch (error) {
      console.error('ステータスの更新中にエラーが発生しました:', error);
    }
  };

  const handlePublishOrSchedule = async () => {
    if (!selectedDate) {
      alert('公開日を選択してください。');
      return;
    }

    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      const publishData = {
        status: isUrgent ? 'publish' : 'awaiting_publish',
        publisher: {
          id: uid,
          name: user.name
        },
        publish_date: new Date(selectedDate)
      };

      await setDoc(notificationRef, publishData, { merge: true });

      if (isUrgent) {
        const publishNotificationRef = doc(db, 'publish_notifications', notificationId);
        const notificationSnapshot = await getDoc(notificationRef);
        const notificationData = notificationSnapshot.data();

        await setDoc(publishNotificationRef, {
          ...notificationData,
          ...publishData
        });

        await deleteDoc(notificationRef);

        alert('お知らせが即時公開されました。');
      } else {
        alert(`公開予約が完了しました。公開日: ${selectedDate}`);
      }
    } catch (error) {
      console.error('公開処理中にエラーが発生しました:', error);
      alert('公開処理中にエラーが発生しました。');
    }
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const handleUrgentChange = (e) => {
    setIsUrgent(e.target.checked);
    if (e.target.checked) {
      setSelectedDate(getTodayDate());
    } else {
      setSelectedDate('');
    }
  };

  const handleCheckboxChange = (e) => {
    setChecks({
      ...checks,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {/* 左側のプレビューエリア */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* 言語選択タブ */}
        <div style={{ display: 'flex', width: '375px', marginBottom: '10px' }}>
          <button
            onClick={() => setSelectedLanguage('ja')}
            style={{
              marginRight: '10px', 
              width: '50%',
              padding: '10px',
              backgroundColor: selectedLanguage === 'ja' ? '#D6BDA5' : '#f8f9fa',
              color: selectedLanguage === 'ja' ? 'white' : 'black',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            日本語
          </button>
          <button
            onClick={() => setSelectedLanguage('en')}
            style={{
              width: '50%',
              padding: '10px',
              backgroundColor: selectedLanguage === 'en' ? '#D6BDA5' : '#f8f9fa',
              color: selectedLanguage === 'en' ? 'white' : 'black',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            English
          </button>
        </div>
        <div style={{ 
          width: '375px', 
          height: '667px', 
          border: '1px solid #ccc', 
          overflow: 'auto', 
          padding: '10px',
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)'
        }}>
          <h3>{selectedLanguage === 'ja' ? title_ja : title_en}</h3>
          <div dangerouslySetInnerHTML={{ __html: selectedLanguage === 'ja' ? content_ja : content_en }} />
        </div>
      </div>

      {/* 中央のコメントと差し戻しエリア */}
      <div style={{ width: '33%', padding: '20px' }}>
        <h3>コメント</h3>
        <div style={{ height: '400px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.id} style={{ marginBottom: '10px' }}>
                <p style={{ marginBottom: '5px' }}>{comment.text}</p>
                <p style={{ fontSize: '12px', color: 'gray' }}>{new Date(comment.createdAt.seconds * 1000).toLocaleString()}</p>
                <hr />
              </div>
            ))
          ) : (
            <p>コメントはありません。</p>
          )}
        </div>

        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="コメントを入力"
          rows="3"
          style={{ width: '100%', padding: '10px', marginBottom: '10px', boxSizing: 'border-box' }}
        />
        <button 
          onClick={handleAddComment} 
          className="login-button" 
          style={{ width: '100%', padding: '10px' }}>
          コメントを追加
        </button>

        <button 
          onClick={handleRevertNotification} 
          className="google-button" 
          style={{ width: '100%', padding: '10px', marginTop: '10px' }}>
          差し戻し
        </button>
      </div>

      {/* 右側の公開予約エリア */}
      <div style={{ width: '33%', padding: '20px' }}>
        <h3>公開予約</h3>
        <label htmlFor="publicationDate">公開日付を選択</label>
        <input
          type="date"
          id="publicationDate"
          min={isUrgent ? getTodayDate() : getTomorrowDate()}
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '10px', boxSizing: 'border-box' }}
          disabled={isUrgent}
        />

        <label>
          <input
            type="checkbox"
            checked={isUrgent}
            onChange={handleUrgentChange}
          />
          緊急公開
        </label>

        <hr style={{ margin: '20px 0' }} />

        <h4>公開前チェック項目</h4>

        <div style={{ marginBottom: '10px' }}>
          <label>
            <input
              type="checkbox"
              name="confidentialInfo"
              checked={checks.confidentialInfo}
              onChange={handleCheckboxChange}
            />
            機密情報の漏洩がないか、著作権や個人情報などが含まれてないか
          </label><br/>
          <label>
            <input
              type="checkbox"
              name="branding"
              checked={checks.branding}
              onChange={handleCheckboxChange}
            />
            読み手に適した内容で、CONOTYらしく優しい書き方になっているか
          </label><br/>
          <label>
            <input
              type="checkbox"
              name="imageUsage"
              checked={checks.imageUsage}
              onChange={handleCheckboxChange}
            />
            画像や図表が適切に使用され、必要な場合著作権表示がされているか
          </label><br/>
          <label>
            <input
              type="checkbox"
              name="linkCorrect"
              checked={checks.linkCorrect}
              onChange={handleCheckboxChange}
            />
            リンクが正しく機能しているか（一度開いて確認する）
          </label><br/>
          <label>
            <input
              type="checkbox"
              name="timingAppropriate"
              checked={checks.timingAppropriate}
              onChange={handleCheckboxChange}
            />
            公開のタイミングが適切か（情報公開可能日以前で公開日が設定されていないか）
          </label>
        </div>

        <button 
          onClick={handlePublishOrSchedule} 
          className="login-button" 
          style={{
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            backgroundColor: isUrgent ? 'red' : '',
            color: isUrgent ? 'white' : ''
          }}
          disabled={!isFormValid}
        >
          {isUrgent ? '即時公開' : '公開予約'}
        </button>

        {isUrgent && (
          <p style={{ color: 'red', marginTop: '10px' }}>
            情報は即時全世界に公開されます。再度問題がないか確認してください。
          </p>
        )}
      </div>
    </div>
  );
};

NotificationApprovalPage.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default NotificationApprovalPage;