import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore"; 
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Storageのインポート

const firebaseConfig = {
  apiKey: "AIzaSyBuO5ihxdfnrOptSsgLcQZZqCPWvZ7jdW8",
  authDomain: "conoty-a33b9.firebaseapp.com",
  databaseURL: "https://conoty-a33b9-default-rtdb.firebaseio.com",
  projectId: "conoty-a33b9",
  storageBucket: "conoty-a33b9.appspot.com",
  messagingSenderId: "105912590386",
  appId: "1:105912590386:web:48bfdeccf06667ee2e9c2b",
  measurementId: "G-PSW8JEDTBK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Storageの初期化

export { db, auth, storage }; // storageをエクスポート

// Firestoreからユーザー情報を取得する関数
export const fetchUserData = async (uid) => {
  const userDoc = doc(db, 'users', uid);
  const userSnapshot = await getDoc(userDoc);
  if (userSnapshot.exists()) {
    return userSnapshot.data();
  } else {
    throw new Error('User does not exist');
  }
};
