import React, { useState, useEffect } from 'react';
import ManualForm from './ManualForm';
import ManualPreview from './ManualPreview';
import { db } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import ManualApprovalPage from './ManualApprovalPage';
import ManualAwaitingPreview from './ManualalAwaitingPreview';

// HTMLをプレーンテキストに変換する関数
const parseHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

// インラインスタイルをオブジェクトで定義
const styles = {
  feedbackList: {
    padding: '40px 240px',
  },
  heading: {
    marginBottom: '20px',
  },
  createButton: {
    marginBottom: '20px',
    padding: '10px 20px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  sectionHeading: {
    marginBottom: '10px',
    marginTop: '40px',
  },
  listContainer: {
    marginTop: '10px',
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #ccc',
  },
  itemContent: {
    flex: 1,
    fontSize: '14px',
    lineHeight: '1.5',
  },
  itemText: {
    margin: '0 0 5px 0',
  },
  buttonGroup: {
    display: 'flex',
    gap: '10px',
  },
  editButton: {
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#D58A6A',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  previewButton: {
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContent: {
    width: '95%',
    maxWidth: '95vw',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    overflowY: 'auto',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#D6BDA5',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

const ManualManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [awaitingApprovals, setAwaitingApprovals] = useState([]);
  const [awaitingPublishes, setAwaitingPublishes] = useState([]);
  const [published, setPublished] = useState([]);
  const [selectedManualId, setSelectedManualId] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isAwaitingPreviewOpen, setIsAwaitingPreviewOpen] = useState(false);
  const [isPublishedPreviewOpen, setIsPublishedPreviewOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  const openPreview = (id) => {
    setSelectedManualId(id);
    setIsPreviewOpen(true);
  };

  const openPublishedPreview = (id) => {
    setSelectedManualId(id);
    setIsPublishedPreviewOpen(true);
  };

  const openAwaitingPreview = (id) => {
    setSelectedManualId(id);
    setIsAwaitingPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedManualId(null);
  };

  const closePublishedPreview = () => {
    setIsPublishedPreviewOpen(false);
    setSelectedManualId(null);
  };

  const closeAwaitingPreview = () => {
    setIsAwaitingPreviewOpen(false);
    setSelectedManualId(null);
  };

  const handleCreateManual = (manualData) => {
    console.log('Create manual:', manualData);
    closeModal();
    fetchDrafts();
    fetchAwaitingApproval();
    fetchAwaitingPublish();
    fetchPublished();
  };

  const fetchDrafts = async () => {
    try {
      const q = query(collection(db, 'manuals'), where('status', '==', 'draft'));
      const querySnapshot = await getDocs(q);
      const fetchedDrafts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDrafts(fetchedDrafts);
    } catch (error) {
      console.error('下書きの取得中にエラーが発生しました:', error);
    }
  };

  const fetchAwaitingApproval = async () => {
    try {
      const q = query(collection(db, 'manuals'), where('status', '==', 'awaiting_approval'));
      const querySnapshot = await getDocs(q);
      const fetchedAwaitingApprovals = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAwaitingApprovals(fetchedAwaitingApprovals);
    } catch (error) {
      console.error('承認待ち一覧の取得中にエラーが発生しました:', error);
    }
  };

  const fetchAwaitingPublish = async () => {
    try {
      const q = query(collection(db, 'manuals'), where('status', '==', 'awaiting_publish'));
      const querySnapshot = await getDocs(q);
      const fetchedAwaitingPublishes = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAwaitingPublishes(fetchedAwaitingPublishes);
    } catch (error) {
      console.error('公開待ち一覧の取得中にエラーが発生しました:', error);
    }
  };

  const fetchPublished = async () => {
    try {
      const q = query(collection(db, 'publish_manuals'));
      const querySnapshot = await getDocs(q);
      const fetchedPublished = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPublished(fetchedPublished);
    } catch (error) {
      console.error('公開済み一覧の取得中にエラーが発生しました:', error);
    }
  };

  useEffect(() => {
    fetchDrafts();
    fetchAwaitingApproval();
    fetchAwaitingPublish();
    fetchPublished();
  }, []);

  const handleEditDraft = (id) => {
    setSelectedManualId(id);
    openModal();
  };

  const handleDeleteDraft = async (id) => {
    const confirmDelete = window.confirm('この下書きを削除しますか？');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'manuals', id));
        setDrafts(drafts.filter(draft => draft.id !== id));
        console.log(`Deleted draft with ID: ${id}`);
      } catch (error) {
        console.error('Error deleting draft:', error);
      }
    }
  };

  return (
    <div style={styles.feedbackList}>
      <h1 style={styles.heading}>マニュアル管理</h1>

      <button
        onClick={() => {
          setSelectedManualId(null);
          openModal();
        }}
        style={styles.createButton}
      >
        新しいマニュアルを作成
      </button>

      <h2 style={styles.sectionHeading}>下書き一覧</h2>
      <div style={styles.listContainer}>
        {drafts.length > 0 ? (
          drafts.map((draft) => (
            <div key={draft.id} style={styles.item}>
              <div style={styles.itemContent}>
                <p style={styles.itemText}>ID: {draft.id}</p>
                <p style={styles.itemText}>件名: {draft.title_ja}</p>
                <p style={styles.itemText}>本文: {parseHtmlString(draft.content_ja || '内容なし').substring(0, 20)}...</p>
                <p style={styles.itemText}>最終変更日時: {new Date(draft.updatedAt?.seconds * 1000).toLocaleString()}</p>
              </div>
              <div style={styles.buttonGroup}>
                <button onClick={() => handleEditDraft(draft.id)} style={styles.editButton}>編集</button>
                <button onClick={() => handleDeleteDraft(draft.id)} style={styles.deleteButton}>削除</button>
              </div>
            </div>
          ))
        ) : (
          <p>下書きはありません</p>
        )}
      </div>

      <h2 style={styles.sectionHeading}>承認待ち一覧</h2>
      <div style={styles.listContainer}>
        {awaitingApprovals.length > 0 ? (
          awaitingApprovals.map((approval) => (
            <div key={approval.id} style={styles.item}>
              <div style={styles.itemContent}>
                <p style={styles.itemText}>ID: {approval.id}</p>
                <p style={styles.itemText}>件名: {approval.title_ja}</p>
                <p style={styles.itemText}>本文: {parseHtmlString(approval.content_ja || '内容なし').substring(0, 20)}...</p>
                <p style={styles.itemText}>最終変更日時: {new Date(approval.updatedAt?.seconds * 1000).toLocaleString()}</p>
              </div>

              <div style={styles.buttonGroup}>
                <button onClick={() => openPreview(approval.id)} style={styles.previewButton}>下書きプレビュー</button>
              </div>
            </div>
          ))
        ) : (
          <p>承認待ちのマニュアルはありません</p>
        )}
      </div>

      <h2 style={styles.sectionHeading}>公開待ち一覧</h2>
      <div style={styles.listContainer}>
        {awaitingPublishes.length > 0 ? (
          awaitingPublishes.map((publish) => (
            <div key={publish.id} style={styles.item}>
              <div style={styles.itemContent}>
                <p style={styles.itemText}>ID: {publish.id}</p>
                <p style={styles.itemText}>件名: {publish.title_ja}</p>
                <p style={styles.itemText}>本文: {parseHtmlString(publish.content_ja || '内容なし').substring(0, 20)}...</p>
                <p style={styles.itemText}>最終変更日時: {new Date(publish.updatedAt?.seconds * 1000).toLocaleString()}</p>
              </div>

              <div style={styles.buttonGroup}>
                <button onClick={() => openAwaitingPreview(publish.id)} style={styles.previewButton}>公開プレビュー</button>
              </div>
            </div>
          ))
        ) : (
          <p>公開待ちのマニュアルはありません</p>
        )}
      </div>

      <h2 style={styles.sectionHeading}>公開済み一覧</h2>
      <div style={styles.listContainer}>
        {published.length > 0 ? (
          published.map((publish) => (
            <div key={publish.id} style={styles.item}>
              <div style={styles.itemContent}>
                <p style={styles.itemText}>ID: {publish.id}</p>
                <p style={styles.itemText}>件名: {publish.title_ja}</p>
                <p style={styles.itemText}>本文: {parseHtmlString(publish.content_ja || '内容なし').substring(0, 20)}...</p>
                <p style={styles.itemText}>最終変更日時: {new Date(publish.updatedAt?.seconds * 1000).toLocaleString()}</p>
              </div>

              <div style={styles.buttonGroup}>
                <button onClick={() => openPublishedPreview(publish.id)} style={styles.previewButton}>プレビュー</button>
              </div>
            </div>
          ))
        ) : (
          <p>公開済みのマニュアルはありません</p>
        )}
      </div>

      {isModalOpen && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <ManualForm 
              onSubmit={handleCreateManual}
              onCancel={closeModal}
              manualId={selectedManualId}
            />
          </div>
        </div>
      )}

      {isPreviewOpen && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <ManualApprovalPage manualId={selectedManualId} />
            <button onClick={closePreview} style={styles.closeButton}>閉じる</button>
          </div>
        </div>
      )}
      {isAwaitingPreviewOpen && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <ManualAwaitingPreview manualId={selectedManualId} />
            <button onClick={closeAwaitingPreview} style={styles.closeButton}>閉じる</button>
          </div>
        </div>
      )}
      {isPublishedPreviewOpen && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <ManualPreview manualId={selectedManualId} />
            <button onClick={closePublishedPreview} style={styles.closeButton}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
};



export default ManualManagement;
